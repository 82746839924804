

// lambdaService.js
import AWS from 'aws-sdk';

const stage = process.env.SERVERLESS_STAGE || 'dev'
const projectName = process.env.SERVERLESS_PJ_NAME || 'crisis24'
const functionName = process.env.SERVERLESS_SERVICE_NAME || 'lambda'

const accessKeyId = process.env.AWS_ACCESS_KEY_ID || 'AAFBBE1237'
const secretAccessKey = process.env.AWS_SECRET_ACCESS_KEY || 'BEERNHE89123745'
const region = process.env.AWS_REGION || 'ap-southeast-1'

// AWS credentials
// const AWS = require('aws-sdk');
AWS.config.update({
  accessKeyId: accessKeyId,
  secretAccessKey: secretAccessKey,
  region: region,
});


const lambda = new AWS.Lambda();

const invokeLambdaFunction = async (payload) => {
  const params = {
    FunctionName: projectName + '-' + stage + '-' + functionName, // Replace with your Lambda function name
    InvocationType: 'Event',
    Payload: JSON.stringify(payload),
  };
  
  params.FunctionName += functionName
  try {
    const data = await lambda.invoke(params).promise();
    return JSON.parse(data.Payload);
  } catch (error) {
    console.error('Error invoking Lambda function:', error);
    throw error;
  }
};

export default invokeLambdaFunction;