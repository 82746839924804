// src/App.js
import React from 'react';
import { Helmet } from 'react-helmet';
import Header from './Header';
import ContactForm from './ContactForm'; // Import the ContactForm component
import MainContent from './MainContent';
import Footer from './Footer';
import './App.css';
import logo from './logo.svg';

function App() {
  
  const handleFormSubmit = (payload) => {
    // Here, you can invoke your Lambda function using the payload
    console.log('Submitting payload to Lambda:', payload);
    // Implement Lambda invocation logic here
  };


  return (
    <div className="App">
      <head>
      <meta httpEquiv="Content-Security-Policy" content="frame-ancestors https://*.awsapps.com https://c24-demo-instance.my.connect.aws" />
      </head>
      <Header />
      <MainContent />
      <ContactForm onSubmit={handleFormSubmit} />
      <Footer />
      <logo />
    </div>
  );
}

export default App;
