// ContactForm.js
import React, { useState } from 'react';
import invokeLambdaFunction from './lambdaService';

const ContactForm = () => {
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      agent_login: email,
      outbound_number: phoneNumber,
    };

    try {
      const lambdaResponse = await invokeLambdaFunction(payload);
      console.log('Lambda function response:', lambdaResponse);
      // Add any additional logic based on the Lambda response
    } catch (error) {
      // Handle errors from Lambda invocation
      console.error('Error invoking Lambda function:', error);
    }

    // Optionally, clear the form fields
    setEmail('');
    setPhoneNumber('');
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Email:
        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
      </label>
      <br />
      <label>
        Phone Number:
        <input type="tel" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
      </label>
      <br />
      <button type="submit">Submit</button>
    </form>
  );
};

export default ContactForm;
